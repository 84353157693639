import React from 'react';
import PropTypes from 'prop-types';

const DiscordUserLink = ({ userId, children, className, ...props }) => {
    const handleClick = (e) => {
        e.preventDefault();
        const appUrl = `discord://discordapp.com/users/${userId}`;
        const webUrl = `https://discord.com/users/${userId}`;

        // Try to open the Discord app
        window.location.href = appUrl;

        // Fallback to opening in a new tab after a short delay
        setTimeout(() => {
            window.open(webUrl, '_blank', '');
        }, 500);
    };

    return (
        <a
            href={`https://discord.com/users/${userId}`}
            onClick={handleClick}
            className={className}
            {...props}
        >
            {children || 'Message User'}
        </a>
    );
};

DiscordUserLink.propTypes = {
    userId: PropTypes.string.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default DiscordUserLink;
