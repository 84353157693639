import React from "react";
import { RiDiscordFill } from "@remixicon/react";
import { useAuth } from "../../util/auth";
import TextField from "../TextField";
import Button from "../Button";

function DiscordAccountSection() {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:3000/';
    const auth = useAuth();
    const discordUser = auth?.user.discord_users;

    const disabledButtonClass = discordUser
        ? 'bg-gray-500 text-white border-0 cursor-not-allowed opacity-50 hover:bg-gray-500 hover:text-white focus:bg-gray-500 focus:text-white active:bg-gray-500 active:text-white'
        : '';

    return (
        <>
            <TextField
                type="text"
                id="discord_link"
                name="discord_link"
                placeholder="Discord username"
                defaultValue={discordUser?.username}
                readOnly
                inputClassName="cursor-default"
                onClick={() => {
                    if (!discordUser) {
                        document.getElementById('discordConnectBtn').click();
                    }
                }}
            />
            <div className="text-right">
                <Button
                    id="discordConnectBtn"
                    size="lg"
                    variant={discordUser ? "secondary" : "primary"}
                    href={discordUser ? undefined : `${BACKEND_URL}passport/discord?userId=${auth?.user.id}`}
                    target="_blank"
                    className={`w-full md:w-auto ${disabledButtonClass}`}
                    disabled={!!discordUser}
                >
                    <RiDiscordFill size={20} className="inline-block mr-0.5 -mt-px align-text-top" />
                    {discordUser ? 'Discord Connected' : 'Connect Discord'}
                </Button>
            </div>
        </>
    );
}

export default DiscordAccountSection;