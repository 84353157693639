import React from "react";
import Auth from "./Auth";

function AuthSection(props) {

    return (
        <div className="w-full h-full lg:flex justify-between lg:flex-col">
            <div></div>
            <div className="px-3 lg:px-0">
                <div className="lg:hidden py-8">
                    <img
                        className=""
                        src="/svg/kavo-logo.svg"
                        width={102}
                        height={24}
                    />
                </div>
                <h2 className="font-semibold text-2xl lg:text-center mb-3 lg:mb-4">
                    Sign in
                </h2>
                <p className="text-darkgrey lg:text-center mb-12 lg:mb-16 text-sm lg:text-base font-normal">
                    Log in to your Kavo account
                </p>
                <Auth
                    type="signin"
                    providers={props.providers}
                    afterAuthPath={props.afterAuthPath}
                    key="signin"
                />
            </div>
            <div className="hidden lg:block m-12 text-center text-sm text-darkgrey">
                &copy; {new Date().getFullYear()} Kavo. All rights reserved.
                <a href="/legal/terms-of-service" className="text-link ml-2">
                    Terms & Conditions
                </a>
                <a href="/legal/privacy-policy" className="text-link ml-2">
                    Privacy Policy
                </a>
                <a href="/faq" className="text-link ml-2">
                    Frequently Asked Questions
                </a>
            </div>
        </div>
    );
}

export default AuthSection;
