import React from "react";
import { Link } from "react-router-dom";
import { RiMenuLine } from "@remixicon/react";

const Header = ({ handleSidebarOpen }) => {
    return (
        <div className="sbar:hidden flex pl-4 pr-2 justify-between items-center h-14 bg-white border-b border-[#DADFE8]">
            <Link to="/">
                <img
                    className="block cursor-pointer"
                    src="/svg/kavo-logo.svg"
                    width={102}
                    height={24}
                    alt="Kavo Logo"
                />
            </Link>
            <div
                className="p-2 cursor-pointer"
                onClick={handleSidebarOpen}
            >
                <RiMenuLine size={24} />
            </div>
        </div>
    );
};

export default Header;