import React, { useEffect, useState, useCallback } from "react";
import { useQuery } from "react-query";
import Meta from "./../components/Meta";
import { requireAuth } from "./../util/auth";
import PageTitle from "../components/common/PageTitle";
import { history, useRouter } from "./../util/router";
import DealsTable from "./dealsTable";
import Tabs from "../components/common/Tabs";
import {
    fetchHistoryPurchases,
    fetchHistorySales,
    fetchPurchases,
    fetchSales,
    PER_PAGE
} from "../util/api";
import LoadingIcon from "../components/LoadingIcon";
import Pagination from "../components/navigation/Pagination";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import debounce from 'lodash/debounce';
import { RiCloseLine, RiEqualizerLine } from "@remixicon/react";
import ClickAwayListener from "react-click-away-listener";
import SearchFilter from "../components/common/SearchFilter";

function DealsPage({ title, dealType }) {
    const urlParams = new URLSearchParams(window.location.search);
    const initialPage = urlParams.get('page');
    const router = useRouter();
    const [activeTab, setActiveTab] = useState("CURRENT");
    const [currentPage, setCurrentPage] = useState(activeTab === "CURRENT" && initialPage ? initialPage : 1);
    const [historyPage, setHistoryPage] = useState(activeTab === "HISTORY" && initialPage ? initialPage : 1);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [tabCounts, setTabCounts] = useState({ CURRENT: 0, HISTORY: 0 });

    useEffect(() => {
        const querySection = router.query.section?.toUpperCase();
        const targetTab = ["CURRENT", "HISTORY"].includes(querySection) ? querySection : "CURRENT";
        setActiveTab(targetTab);
        if (activeTab === "CURRENT") {
            setCurrentPage(urlParams.get('page'));
        } else {
            setHistoryPage(urlParams.get('page'));
        }
    }, [router.query.section, dealType]);

    const fetchDealsData = async ({ queryKey }) => {
        const [, dealType, tab, page, search, start, end] = queryKey;
        const params = {
            page: page,
            limit: PER_PAGE,
            search: search,
            startDate: start ? start.toISOString() : null,
            endDate: end ? end.toISOString() : null
        };

        let data;
        if (dealType === 'WTB') {
            data = tab === 'CURRENT' ? await fetchPurchases(params) : await fetchHistoryPurchases(params);
        } else {
            data = tab === 'CURRENT' ? await fetchSales(params) : await fetchHistorySales(params);
        }

        // Update the count for the current tab
        setTabCounts(prev => ({
            ...prev,
            [tab]: data.pagination.total
        }));

        return data;
    };

    const { data: currentData, isLoading: currentLoading, isFetching: currentFetching, error: currentError } = useQuery(
        ['deals', dealType, 'CURRENT', currentPage, searchTerm, startDate, endDate],
        fetchDealsData,
        { keepPreviousData: true }
    );

    const { data: historyData, isLoading: historyLoading, isFetching: historyFetching, error: historyError } = useQuery(
        ['deals', dealType, 'HISTORY', historyPage, searchTerm, startDate, endDate],
        fetchDealsData,
        { keepPreviousData: true }
    );

    const DEAL_TYPE_NAMES_PLURAL = {
        WTB: "purchases",
        WTS: "sales",
    };

    const TAB_ITEMS = {
        CURRENT: { slug: `/${DEAL_TYPE_NAMES_PLURAL[dealType]}/current`, label: `Current ${DEAL_TYPE_NAMES_PLURAL[dealType]}`, count: tabCounts.CURRENT },
        HISTORY: { slug: `/${DEAL_TYPE_NAMES_PLURAL[dealType]}/history`, label: "History", count: tabCounts.HISTORY },
    };

    const handlePageChange = (newPage) => {
        if (activeTab === "CURRENT") {
            setCurrentPage(newPage);
        } else {
            setHistoryPage(newPage);
        }
        history.push({
            search: `?page=${newPage}`,
        });
    };

    // Debounced search function
    const debouncedSearch = useCallback(
        debounce((value) => {
            setSearchTerm(value);
            setCurrentPage(1);
            setHistoryPage(1);
        }, 300),
        []
    );

    const handleSearch = (e) => {
        e.preventDefault();
        const value = e.target.value;
        debouncedSearch(value);
    };

    const handleDateChange = (date, isStart) => {
        if (isStart) {
            setStartDate(date);
        } else {
            setEndDate(date);
        }
        setCurrentPage(1);
        setHistoryPage(1);
    };

    const handleDateFilterClose = () => {
        setShowCalendar(false);
    };

    const handleSearchModalClose = () => {
        setShowSearchModal(false);
    };

    const handleClearFilters = () => {
        setSearchTerm(null);
        setStartDate(null);
        setEndDate(null);
    };

    const isLoading = activeTab === "CURRENT" ? currentLoading || currentFetching : historyLoading || historyFetching;
    const error = activeTab === "CURRENT" ? currentError : historyError;
    const data = activeTab === "CURRENT" ? currentData : historyData;
    const currentPageForTab = activeTab === "CURRENT" ? currentPage : historyPage;

    return (
        <>
            <Meta title={`${title} - Kavo`}/>
            <PageTitle title={title}/>

            <Tabs activetab={activeTab} tabItems={TAB_ITEMS}>
                <SearchFilter
                    handleSearchModalClose={handleSearchModalClose}
                    searchTerm={searchTerm}
                    handleSearch={handleSearch}
                    showSearchModal={showSearchModal}
                    setShowSearchModal={setShowSearchModal}
                />
                <span className="relative ml-2">
                    <span
                        className="p-1 cursor-pointer block border-[#DADFE8] lg:rounded-lg lg:h-9 lg:bg-white lg:border lg:flex items-center lg:px-2.5"
                        onClick={() => setShowCalendar(true)}
                    >
                        <RiEqualizerLine size={20} className="text-darkgrey" />
                        <span className="hidden lg:inline lg:ml-1.5 text-darkgrey text-sm font-normal">
                            Filters
                        </span>
                    </span>
                    {showCalendar && (
                        <ClickAwayListener onClickAway={handleDateFilterClose} touchEvent="" focusEvent="focusout">
                            <div className="bg-white absolute rounded-xl w-[300px] right-0 top-auto shadow-lg py-3.5 lg:mt-0.5 z-10">
                                <div className="w-[268px] mx-auto">
                                    <div className="mb-2.5 flex items-center modal-header">
                                        Filter by date
                                        <div
                                            className="w-7 h-7 ml-auto flex items-center justify-center text-gray-800 cursor-pointer"
                                            onClick={handleDateFilterClose}
                                        >
                                            <RiCloseLine size={22} />
                                        </div>
                                    </div>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => handleDateChange(date, true)}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        placeholderText="Start Date"
                                        className={`text-base pl-2.5 pr-6 border rounded-lg w-[130px] border-[#DADFE8] font-normal text-normal text-[#001C2C] focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50`}
                                        isClearable
                                        dateFormat="dd.MM.yyyy"
                                    />
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => handleDateChange(date, false)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        placeholderText="End Date"
                                        className={`text-base pl-2.5 pr-6 border rounded-lg w-[130px] border-[#DADFE8] font-normal text-normal text-[#001C2C] focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50`}
                                        isClearable
                                        dateFormat="dd.MM.yyyy"
                                    />
                                </div>
                            </div>
                        </ClickAwayListener>
                    )}
                </span>
            </Tabs>

            {
                (startDate || endDate || searchTerm) && (
                    <div className="flex items-center mx-4 lg:mx-6 text-sm mb-5 text-[#333] italic">
                        Filtered by: {[
                            startDate ? `from ${startDate.toLocaleDateString()}` : null,
                            endDate ? `until ${endDate.toLocaleDateString()}` : null,
                            searchTerm ? `search for '${searchTerm}'` : null,
                        ].filter(n => n).join(', ')}
                        <div
                            className="w-7 h-7 ml-1 inline-flex items-center justify-center text-gray-800 cursor-pointer"
                            onClick={handleClearFilters}
                        >
                            <RiCloseLine size={20} />
                        </div>
                    </div>
                )
            }

            {isLoading && (
                <div className="mt-16 text-center">
                    <LoadingIcon className="inline-block w-6"/>
                </div>
            )}

            {error && (
                <div className="mt-16 text-center text-red-500">
                    An error occurred while fetching data.
                </div>
            )}

            {!isLoading && !error && data && (
                <>
                    <DealsTable data={data.data} dealType={dealType} />
                    <Pagination
                        pagination={{...data.pagination, currentPage: currentPageForTab}}
                        onPageChange={handlePageChange}
                    />
                </>
            )}
        </>
    );
}

export default requireAuth(DealsPage);