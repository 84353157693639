import React from "react";
import ReactFlagsSelect from "react-flags-select";

function CountrySelector({ error, field }) {

    return (
        <div className="w-full">
            <ReactFlagsSelect
                id="country"
                className="w-full bg-[#FCFCFD] block border placeholder-gray-400 w-full rounded-lg focus:z-10 border-[#DADFE8] focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 h-11 leading-6"
                countries={[
                    "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT",
                    "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE",
                    "AL", "AD", "BA", "CH", "FO", "GI", "IS", "LI", "MC", "ME", "MK", "NO", "SM", "RS", "UA", "VA", "UK"
                ]}
                customLabels={{
                    "AT": { primary: "Austria" },
                    "BE": { primary: "Belgium" },
                    "BG": { primary: "Bulgaria" },
                    "HR": { primary: "Croatia" },
                    "CY": { primary: "Cyprus" },
                    "CZ": { primary: "Czech Republic" },
                    "DK": { primary: "Denmark" },
                    "EE": { primary: "Estonia" },
                    "FI": { primary: "Finland" },
                    "FR": { primary: "France" },
                    "DE": { primary: "Germany" },
                    "GR": { primary: "Greece" },
                    "HU": { primary: "Hungary" },
                    "IE": { primary: "Ireland" },
                    "IT": { primary: "Italy" },
                    "LV": { primary: "Latvia" },
                    "LT": { primary: "Lithuania" },
                    "LU": { primary: "Luxembourg" },
                    "MT": { primary: "Malta" },
                    "NL": { primary: "Netherlands" },
                    "PL": { primary: "Poland" },
                    "PT": { primary: "Portugal" },
                    "RO": { primary: "Romania" },
                    "SK": { primary: "Slovakia" },
                    "SI": { primary: "Slovenia" },
                    "ES": { primary: "Spain" },
                    "SE": { primary: "Sweden" },
                    "AL": { primary: "Albania" },
                    "AD": { primary: "Andorra" },
                    "BA": { primary: "Bosnia and Herzegovina" },
                    "CH": { primary: "Switzerland" },
                    "FO": { primary: "Faroe Islands" },
                    "GI": { primary: "Gibraltar" },
                    "IS": { primary: "Iceland" },
                    "LI": { primary: "Liechtenstein" },
                    "MC": { primary: "Monaco" },
                    "ME": { primary: "Montenegro" },
                    "MK": { primary: "North Macedonia" },
                    "NO": { primary: "Norway" },
                    "SM": { primary: "San Marino" },
                    "RS": { primary: "Serbia" },
                    "UA": { primary: "Ukraine" },
                    "VA": { primary: "Vatican City" },
                    "UK": { primary: "United Kingdom" }
                }}
                selected={field.value}
                onSelect={(code) => { field.onChange(code) }}
            />
            {error && <p className="text-sm text-left text-red-600 mt-1">Choose your country</p>}
        </div>
    );
};

export default CountrySelector;
