import React from "react";
import { Link } from "./../util/router";
import { requireAuth } from "../util/auth";
import ContentBox from "../components/common/ContentBox";
import { RiDiscordFill } from "@remixicon/react";
import {formatDate} from "../util/formatter";
import DiscordUserLink from "../components/common/DiscordUserLink";
import StateTag from "../components/common/StateTag";

function DealsTable({ data, dealType }) {
    const DEAL_TYPE_NAMES = {
        WTB: "purchase",
        WTS: "sale",
    }

    const NoDataMessage = () => (
        <>No {DEAL_TYPE_NAMES[dealType]}s data available.</>
    );

    const calculateDealTotal = (dealItems) => {
        try {
            const total = dealItems.reduce((acc, item) => {
                if (!item.price) return acc;
                const price = parseFloat(item.price.replace('€', '').trim());
                return acc + (isNaN(price) ? 0 : price);
            }, 0);

            return total === 0 ? '-' : total.toFixed(2) + '€';
        } catch (error) {
            return '-';
        }
    };

    return (
        <>
            <div className="lg:hidden">
                {data && data.length > 0 ? (
                    data.map((deal) => (

                        <ContentBox
                            classes="mb-3.5"
                            key={deal.id}
                        >
                            <div className="pb-3 px-4 -ml-4 -mr-4 border-b border-[#DADFE8]">
                                <Link
                                    className="text-sm py-1 text-link capitalize"
                                    to={`/${DEAL_TYPE_NAMES[dealType]}/${deal.id}`}
                                >
                                    {DEAL_TYPE_NAMES[dealType]} ID #{deal.unique_id}
                                </Link>
                            </div>
                
                            <table className="w-full table-auto mt-1">
                                <tbody>
                                <tr>
                                    <td className="py-2.5 w-40 text-xs uppercase text-darkgrey">Date</td>
                                    <td className="py-2.5 text-sm">{formatDate(deal.created_at)}</td>
                                </tr>
                                <tr>
                                    <td className="py-2.5 w-40 text-xs uppercase text-darkgrey">Quantity</td>
                                    <td className="py-2.5 text-sm">{deal.deal_items.reduce((acc, item) => acc + item.quantity, 0)}</td>
                                </tr>
                                <tr>
                                    <td className="py-2.5 w-40 text-xs uppercase text-darkgrey">Total</td>
                                    <td className="py-2.5 text-sm">{calculateDealTotal(deal.deal_items)}</td>
                                </tr>
                                <tr>
                                <td className="py-2.5 w-40 text-xs uppercase text-darkgrey">Channel</td>
                                    <td className="py-2.5 text-sm capitalize">
                                        {deal.channel === 'discord' && <>
                                            <RiDiscordFill className="inline-block align-text-bottom mr-1 align-middle"
                                                        color="#4D57FD" size="16"/>Discord
                                        </>}
                                        {deal.channel !== 'discord' && deal.channel}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="py-2.5 w-40 text-xs uppercase text-darkgrey">Deal with</td>
                                    <td className="py-2.5 text-sm text-link">
                                        <DiscordUserLink
                                            userId={`${dealType === 'WTS' ? deal.buyer.discord_user.discord_id : deal.seller.discord_user.discord_id}`}>
                                            @{dealType === 'WTS' ? deal.buyer.discord_user.username : deal.seller.discord_user.username}
                                        </DiscordUserLink>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="py-2.5 w-40 text-xs uppercase text-darkgrey">State</td>
                                    <td className="py-2.5 text-sm text-link capitalize">
                                        <StateTag dealId={deal.id} initialState={deal.state} type={dealType} />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </ContentBox>
                    ))
                ) : (
                    <div className="text-darkgrey text-sm text-center pt-6 pb-4">
                        <NoDataMessage />
                    </div>
                )}
            </div>

            <div className="hidden lg:block">
                <ContentBox>
                    <table className="w-full text-left text-sm font-medium whitespace-nowrap">
                        <thead className="text-darkgrey text-xs uppercase h-14">
                        <tr>
                            <th className="p-4 bg-grey rounded-l-lg">Date</th>
                            <th className="p-4 bg-grey">{DEAL_TYPE_NAMES[dealType]} ID</th>
                            <th className="p-4 bg-grey">Quantity</th>
                            <th className="p-4 bg-grey">Total</th>
                            <th className="p-4 bg-grey">Channel</th>
                            <th className="p-4 bg-grey">Deal with</th>
                            <th className="p-4 bg-grey">State</th>
                            <th className="p-4 bg-grey rounded-r-lg">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data && data.length > 0 ? (
                            data.map((deal) => (
                                <tr className="h-20" key={deal.id}>
                                    <td className="p-4">{formatDate(deal.created_at)}</td>
                                    <td className="p-4">#{deal.unique_id}</td>
                                    <td className="p-4">{deal.deal_items.reduce((acc, item) => acc + item.quantity, 0)}</td>
                                    <td>{calculateDealTotal(deal.deal_items)}</td>
                                    <td className="p-4 capitalize">
                                        {deal.channel === 'discord' && <>
                                            <RiDiscordFill className="inline-block align-text-bottom mr-1 align-middle" color="#4D57FD" size="16" />Discord
                                        </>}
                                        {deal.channel !== 'discord' && deal.channel}
                                    </td>
                                    <td className="p-4 text-link">
                                        <DiscordUserLink userId={`${dealType === 'WTS' ? deal.buyer.discord_user.discord_id : deal.seller.discord_user.discord_id}`}>
                                            @{dealType === 'WTS' ? deal.buyer.discord_user.username : deal.seller.discord_user.username}
                                        </DiscordUserLink>
                                    </td>
                                    <td className={`p-4 capitalize`}>
                                        <StateTag dealId={deal.id} initialState={deal.state} type={dealType} />
                                    </td>
                                    <td className="p-4">
                                        <Link
                                            className="text-xs h-9 w-28 flex items-center justify-center rounded-lg bg-grey text-link"
                                            to={`/${DEAL_TYPE_NAMES[dealType]}/${deal.id}`}
                                        >
                                            View details
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" className="text-center pt-8 pb-5 text-darkgrey">
                                    <NoDataMessage />
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>

                    <span className="text-[#24962A] text-[#ff7a00] bg-[#FFF4E0] bg-[#EFFFEF]"></span>
                </ContentBox>
            </div>
        </>
    );
}

export default requireAuth(DealsTable);