import React from "react";
import { history } from "../../util/router";
import { RiArrowLeftLine } from "@remixicon/react";
import StateTag from "./StateTag";

function DealDetailHeader({ dealId, id, date, quantity, state, dealType}) {
    console.log('DealDetailHeader', dealId, id, date, quantity, state);

    const handleGoBack = () => {
        if (history.length > 2) {
            history.goBack();
        } else {
            if (dealType === '"WTB') {
                history.replace('/purchases');
            } else {
                history.replace('/sales');
            }
        }
    }

    return (
        <div>
            <div className="border-b border-[#DADFE8] mb-4 mt-1.5 lg:mb-6 font-normal px-4 lg:px-6 pb-4">
                <div className="h-6 flex items-center text-sm">
                    <span onClick={handleGoBack} className="mr-2 cursor-pointer">
                        <RiArrowLeftLine size={24} />
                    </span>
                    <span className="font-bold mr-2.5">Deal #{id}</span>
                    { date && <span className="mr-2.5">{date}</span> }
                    { quantity && <span className="mr-2.5">{quantity} {quantity === 1 ? 'item': 'items'}</span> }
                    { date &&
                        <StateTag dealId={dealId} initialState={state} type={dealType}/>
                    }
                </div>
            </div>
        </div>
    );
}

export default DealDetailHeader;
