import React from "react";
import "./../styles/global.css";
import AboutPage from "./about";
import SettingsOldPage from "./settings-old";
import FaqPage from "./faq";
import ContactPage from "./contact";
import AuthPage from "./auth";
import LegalPage from "./legal";
import { Switch, Route, Router, Redirect } from "./../util/router";
import CheckoutPage from "./checkout";
import NotFoundPage from "./404";
import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import AuthLayout from "./../layouts/authLayout";
import AppLayout from "./../layouts/appLayout";
import SettingsPage from "./settings";
import { DEFAULT_PAGE } from "./../data/nav";
import ListingsPage from "./listings";
import DealsPage from "./deals";
import DealDetailPage from "./dealDetailPage";

function App(props) {
    return (
        <QueryClientProvider>
            <AuthProvider>
                <Router>
                    <>
                        <Switch>
                            <Redirect exact from="/" to={DEFAULT_PAGE} />

                            <Route exact path="/auth/:type">
                                <AuthLayout>
                                    <AuthPage />
                                </AuthLayout>
                            </Route>

                            <Route exact path="/settings/:section">
                                <AppLayout>
                                    <SettingsPage />
                                </AppLayout>
                            </Route>

                            <Route exact path="/purchases/:section">
                                <AppLayout>
                                    <DealsPage dealType="WTB" title="Purchases" />
                                </AppLayout>
                            </Route>

                            <Route exact path="/purchase/:section">
                                <AppLayout>
                                    <DealDetailPage dealType="WTB" title="Purchase details" />
                                </AppLayout>
                            </Route>

                            <Route exact path="/sales">
                                <Redirect to="/sales/current" />
                            </Route>

                            <Route exact path="/sales/:section">
                                <AppLayout>
                                    <DealsPage dealType="WTS" title="Sales" />
                                </AppLayout>
                            </Route>

                            <Route exact path="/sale/:section">
                                <AppLayout>
                                    <DealDetailPage dealType="WTS" title="Sale details" />
                                </AppLayout>
                            </Route>

                            <Route exact path="/listings/:section">
                                <AppLayout>
                                    <ListingsPage />
                                </AppLayout>
                            </Route>

                            {/* TODO: terms, privacy, faq */}
                            <Route exact path="/legal/:section" component={LegalPage} />
                            <Route exact path="/faq" component={FaqPage} />

                            {/* old, not needed for now

                            <Route exact path="/contact" component={ContactPage} />
                            <Route exact path="/about" component={AboutPage} />
                            <Route exact path="/settings-old/:section" component={SettingsOldPage} />

                            */}




                            <Route exact path="/checkout/:plan">
                                <AppLayout>
                                    <CheckoutPage />
                                </AppLayout>
                            </Route>

                            <Route component={NotFoundPage} />
                        </Switch>
                    </>
                </Router>
            </AuthProvider>
        </QueryClientProvider>
    );
}

export default App;
