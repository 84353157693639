import React, { useEffect, useState, useCallback } from "react";
import Meta from "./../components/Meta";
import { requireAuth } from "./../util/auth";
import PageTitle from "../components/common/PageTitle";
import { history, useRouter } from "./../util/router";
import ListingsTable from "./listingsTable";
import Pagination from "../components/navigation/Pagination";
import LoadingIcon from "../components/LoadingIcon";
import { fetchBuyListings, fetchSellListings, PER_PAGE } from "../util/api";
import { useQuery } from "react-query";
import Tabs from "../components/common/Tabs";
import debounce from 'lodash/debounce';
import SearchFilter from "../components/common/SearchFilter";
import { RiCloseLine } from "@remixicon/react";

function ListingsPage() {
    const router = useRouter();
    const urlParams = new URLSearchParams(window.location.search);
    const initialPage = urlParams.get('page');
    const querySection = router.query.section?.toUpperCase();
    const initialTab = ["BUY", "SELL"].includes(querySection) ? querySection : "BUY";
    const [activeTab, setActiveTab] = useState(initialTab);
    const [buyPage, setBuyPage] = useState(activeTab === "BUY" && initialPage ? initialPage : 1);
    const [sellPage, setSellPage] = useState(activeTab === "SELL" && initialPage ? initialPage : 1);
    const [searchTerm, setSearchTerm] = useState('');
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [tabCounts, setTabCounts] = useState({ BUY: 0, SELL: 0 });

    useEffect(() => {
        const querySection = router.query.section?.toUpperCase();
        const targetTab = ["BUY", "SELL"].includes(querySection) ? querySection : "BUY";
        setActiveTab(targetTab);
        if (activeTab === "BUY") {
            setBuyPage(urlParams.get('page'));
        } else {
            setSellPage(urlParams.get('page'));
        }
    }, [router.query.section]);

    const fetchListingsData = async ({ queryKey }) => {
        const [, tab, page, search] = queryKey;
        const params = {
            page: page,
            limit: PER_PAGE,
            search: search
        };

        let data;
        if (tab === "BUY") {
            data = await fetchBuyListings(params);
        } else {
            data = await fetchSellListings(params);
        }

        // Update the count for the current tab
        setTabCounts(prev => ({
            ...prev,
            [tab]: data.pagination.total
        }));

        return data;
    };

    const { data: buyData, isLoading: buyLoading, isFetching: buyFetching, error: buyError } = useQuery(
        ['listings', 'BUY', buyPage, searchTerm],
        fetchListingsData,
        { keepPreviousData: true }
    );

    const { data: sellData, isLoading: sellLoading, isFetching: sellFetching, error: sellError } = useQuery(
        ['listings', 'SELL', sellPage, searchTerm],
        fetchListingsData,
        { keepPreviousData: true }
    );

    const TAB_ITEMS = {
        BUY: { slug: '/listings/buy', label: 'Buy', count: tabCounts.BUY },
        SELL: { slug: '/listings/sell', label: 'Sell', count: tabCounts.SELL },
    };

    const handleListingDeleted = useCallback(() => {
        console.log('handleListingDeleted');

        const listings = (activeTab === "BUY") ? buyData : sellData;

        if (!listings) return;
        
        let hasUndeletedListing = false;

        listings.data.map((listing) => {
            if (!listing.isDeleted) {
                hasUndeletedListing = true;
            }
        });

        if (!hasUndeletedListing) {
            console.log(4, buyPage, sellPage);
            if (activeTab === "BUY") {
                if (buyPage > 1) {
                    setBuyPage(buyPage - 1);
                }
            } else {
                if (sellPage > 1) {
                    setSellPage(sellPage - 1);
                }
            }
        }

    }, [activeTab, buyData, sellData]);

    const handlePageChange = (newPage) => {
        if (activeTab === "BUY") {
            setBuyPage(newPage);
        } else {
            setSellPage(newPage);
        }
        history.push({
            search: `?page=${newPage}`,
        });
    };

    const handleClearFilters = () => {
        setSearchTerm(null);
    };

    // Debounced search function
    const debouncedSearch = useCallback(
        debounce((value) => {
            setSearchTerm(value);
            setBuyPage(1);
            setSellPage(1);
        }, 300),
        []
    );

    const handleSearch = (e) => {
        const value = e.target.value;
        debouncedSearch(value);
    };

    const handleSearchModalClose = () => {
        setShowSearchModal(false);
    };

    const isLoading = activeTab === "BUY" ? buyLoading || buyFetching : sellLoading || sellFetching;
    const error = activeTab === "BUY" ? buyError : sellError;
    const data = activeTab === "BUY" ? buyData : sellData;
    const currentPage = activeTab === "BUY" ? buyPage : sellPage;

    return (
        <>
            <Meta title="Listings - Kavo"/>
            <PageTitle title="Listings"/>

            <Tabs activetab={activeTab} tabItems={TAB_ITEMS}>
                <SearchFilter
                    handleSearchModalClose={handleSearchModalClose}
                    searchTerm={searchTerm}
                    handleSearch={handleSearch}
                    showSearchModal={showSearchModal}
                    setShowSearchModal={setShowSearchModal}
                />
            </Tabs>

            {
                searchTerm && (
                    <div className="flex items-center mx-4 lg:mx-6 text-sm mb-5 text-[#333] italic">
                        Filtered by: {[
                            searchTerm ? `search for '${searchTerm}'` : null,
                        ].filter(n => n).join(', ')}
                        <div
                            className="w-7 h-7 ml-1 inline-flex items-center justify-center text-gray-800 cursor-pointer"
                            onClick={handleClearFilters}
                        >
                            <RiCloseLine size={20} />
                        </div>
                    </div>
                )
            }

            {isLoading && (
                <div className="mt-16 text-center">
                    <LoadingIcon className="inline-block w-6"/>
                </div>
            )}

            {error && (
                <div className="mt-16 text-center text-red-500">
                    An error occurred while fetching data.
                </div>
            )}

            {!isLoading && !error && data && (
                <>
                    <ListingsTable listings={data.data} listingType={activeTab} onListingDeleted={handleListingDeleted} />
                    <Pagination
                        pagination={{...data.pagination, currentPage}}
                        onPageChange={handlePageChange}
                    />
                </>
            )}
        </>
    );
}

export default requireAuth(ListingsPage);