import React, {useEffect, useState} from "react";
import { requireAuth, useAuth } from "./../util/auth";
import ContentBox from "../components/common/ContentBox";
import { RiMailLine, RiFileCopyLine, RiLink, RiLoader4Fill } from "@remixicon/react";
import TextField from "../components/TextField";
import Button from "../components/Button";
import { toast } from "react-toastify";
import InviteProgressBar, {MAX_INVITES} from "../components/common/InviteProgressBar";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import api from "../util/api";
import {formatDate} from "../util/formatter";
import {redirectToBilling, redirectToCheckout} from "../util/stripe";
import { useRouter } from "../util/router";
import FormAlert from "../components/FormAlert";


export const DISABLE_REFERRALS = true;

function SettingsBillingPage(props) {
    const auth = useAuth();
    const router = useRouter();
    const [formAlert, setFormAlert] = useState();
    const [referrals, setReferrals] = useState([]);
    const [subscription, setSubscription] = useState(null);
    const [subscribedCount, setSubscribedCount] = useState(0);
    const [isWaitingForRedirect, setIsWaitingForRedirect] = useState(false);

    const REF_LINK = window.location.origin + '/auth/signup?ref=' + auth.user.referral_code;
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:3000/';

    useEffect(() => {
        const loadSubscription = async () => {
            try {
                const data = await fetchSubscription();
                setSubscription(data.data);
            } catch (err) {
                console.error('Error fetching subscription:', err);
            }
        }
        const loadReferrals = async () => {
            try {
                const data = await fetchReferrals();
                setReferrals(data.data);
                const subscribed = data && data.data ? data.data.filter(referral => referral.state === "subscribed").length : 0;
                setSubscribedCount(subscribed);
            } catch (err) {
                console.error('Error fetching referrals:', err);
            }
        };
        loadReferrals();
        loadSubscription();
    }, []);

    const fetchReferrals = async () => {
        try {
            const response = await api.get('/profile/referrals');
            console.log("fetchReferrals", response);
            return response.data;
        } catch (error) {
            throw error.response ? error.response.data : error;
        }
    }

    const fetchSubscription = async () => {
        try {
            const response = await api.get('/profile/subscription');
            console.log("fetchSubscription", response);
            return response.data;
        } catch (error) {
            throw error.response ? error.response.data : error;
        }
    }

    const handleReferralCopy = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(REF_LINK);
        toast.success('Your invite link was copied successfully!');
    };

    const handleBuyclick = () => {
        if (auth.user) {
            if (auth.user.planIsActive) {
                router.push("/settings/billing");
            } else {
                setIsWaitingForRedirect(true);
                redirectToCheckout("standard").catch((error) => {
                    console.log("checkout error", error);
                    setFormAlert({
                        type: "error",
                        message: error.message,
                    });
                });
            }
        } else {
            router.push("/auth/signup?next=/purchase/standard");
        }
    };

    const handleManageBilling = async (e) => {
        e.preventDefault();
        setIsWaitingForRedirect(true);
        try {
            await redirectToBilling();
        } catch (error) {
            console.error("Error redirecting to billing portal:", error);
            setFormAlert({
                type: "error",
                message: "Failed to open billing portal. Please try again.",
            });
        } finally {
            setIsWaitingForRedirect(false);
        }
    };

    return (
        <>
            <ContentBox classes="max-w-6xl">
                <div className="py-0.5 lg:p-2.5">
                    <h2 className="text-xl text-[#101F2F] font-semibold mt-1 mb-1">
                        Billing info
                    </h2>
                    <p className="text-sm text-darkgrey">
                        Update your billing details here.
                    </p>

                    <div className="flex flex-col lg:flex-row gap-4 w-full mt-6 py-6 border-t border-[#E4E7EC]">
                        <div className="w-80 shrink-0 text-sm font-medium text-[#101F2F]">
                            Plan information
                        </div>
                        {auth.user && auth.user.planIsActive && subscription && (
                            <div className="w-full rounded-lg bg-[#FCFCFD] border border-[#DADFE8] p-3">
                                <RiMailLine size="20" className="block text-darkgrey mb-2" />
                                <div className="text-sm mb-4">
                                    <div className="text-sm text-darkgrey">
                                        Current plan
                                    </div>
                                    <div className="text-sm font-medium">
                                        {subscription.productName}
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row gap-4 justify-between">
                                    <div>
                                        <div className="font-semibold">
                                            ${subscription.price}/{subscription.interval}
                                        </div>
                                        <div className="text-sm text-darkgrey">
                                            Your next payment of ${subscription.nextPaymentAmount} ({subscription.interval}ly) occurs on {formatDate(subscription.nextPaymentDate)}
                                        </div>
                                    </div>
                                    <a
                                        onClick={handleManageBilling}
                                        className="flex whitespace-nowrap shrink-0 rounded-lg w-36 h-11 bg-white text-sm font-medium border border-[#DADFE8] justify-center items-center"
                                        href="#"
                                        target="_blank"
                                    >
                                        Manage billing
                                    </a>
                                </div>
                            </div>
                        )}
                        {auth.user && !auth.user.planIsActive && (
                            <div className="flex justify-center lg:justify-start w-full">
                                <div className="w-[415px] max-w-full">
                                    <div className="text-2xl font-bold px-3 pt-1 mb-3">
                                        Standard
                                    </div>
                                    <div className="px-6 py-6 rounded-3xl shadow-sm flex flex-col bg-[#f6f6f6] border border-[#dedede] relative  max-w-full">
                                        <div className="pb-5 space-y-5 lg:space-y-6 grow font-medium">
                                            <ul className="space-y-4 text-base">
                                                <li className="flex items-center">
                                                    <CheckCircleIcon className="inline-block w-6 h-6 mr-1.5" />
                                                    <span>Join our Discord community</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <CheckCircleIcon className="inline-block w-6 h-6 mr-1.5" />
                                                    <span>Share your offers</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <CheckCircleIcon className="inline-block w-6 h-6 mr-1.5" />
                                                    <span>Get matching deals</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <CheckCircleIcon className="inline-block w-6 h-6 mr-1.5" />
                                                    <span>Sync deals with our web app</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="pt-7 pb-6">
                                            <span className="text-3xl font-extrabold">€5</span> per month
                                        </div>
                                        <Button
                                            size="xl"
                                            isBlock={true}
                                            type="button"
                                            className="rounded-[12px] font-semibold text-[16px]"
                                            onClick={handleBuyclick}
                                        >
                                            Start 30-Day Free Trial
                                            {isWaitingForRedirect && (
                                                <RiLoader4Fill size={22} className="inline-block align-middle ml-2 -mt-0.5 animate-spin" />
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {formAlert && (
                        <div className="my-6 mx-auto max-w-md">
                            <FormAlert type={formAlert.type} message={formAlert.message} />
                        </div>
                    )}

                </div>
            </ContentBox>

            {! DISABLE_REFERRALS && auth.user && auth.user.planIsActive && (
                <ContentBox classes="max-w-6xl mt-7">
                    <div className="py-0.5 lg:p-2.5">
                        <h2 className="text-xl text-[#101F2F] font-semibold mt-1 mb-1">
                            Referrals overview
                        </h2>
                        <p className="text-sm text-darkgrey">
                            Invite your friends and earn free membership!
                        </p>

                        <div className="mt-6 py-6 border-t border-[#E4E7EC]">
                            <div
                                className="flex-col md:flex-row rounded-2xl gap-4 md:gap-8 m-h-20 p-3.5 items-start md:items-center flex bg-gradient-to-b from-[#F4F8FF] to-[#E7EFFF]">

                                <div className="flex gap-4">
                                    <div className="shrink-0">
                                        <img className="block" src="/svg/stars.svg" width={48} height={48}/>
                                    </div>
                                    <div className="">
                                        <div className="font-medium mb-0.5">
                                            Get up to {MAX_INVITES} free extra months!
                                        </div>
                                        <div className="text-darkgrey text-xs">
                                            Receive 1 free month for each referred friend who starts a plan.
                                        </div>
                                    </div>
                                </div>

                                <div className="md:ml-auto w-full md:w-[360px]">
                                    <div className="flex items-end">
                                        <span className="text-sm font-medium mr-4">Invite more people to get rewards</span>
                                        <span
                                            className="text-xs ml-auto text-black font-normal whitespace-nowrap">{subscribedCount}/{MAX_INVITES} people</span>
                                    </div>
                                    <InviteProgressBar invitedCount={subscribedCount}/>
                                </div>

                            </div>
                        </div>

                        <div className="flex flex-col lg:flex-row gap-4 w-full pb-6">
                            <div className="w-80 shrink-0 text-sm font-medium text-[#101F2F]">
                                Invite link
                            </div>
                            <div className="flex w-full flex-col lg:flex-row gap-3 lg:gap-5">
                                <div className="relative w-full">
                                    <RiLink size="20" className="absolute top-3 left-4 text-darkgrey"/>
                                    <TextField
                                        readOnly
                                        type="text"
                                        id="invite_link"
                                        name="discoinvite_linkrd_link"
                                        defaultValue={REF_LINK}
                                        inputClassName="cursor-default pl-11 text-sm"
                                        onClick={() => document.getElementById('copyLinkBtn').click()}
                                    />
                                </div>
                                <div className="text-right">
                                    <Button
                                        id="copyLinkBtn"
                                        size="lg"
                                        variant="primary"
                                        className="w-full md:w-auto"
                                        onClick={handleReferralCopy}
                                        type="button"
                                    >
                                        <RiFileCopyLine size={20} className="mr-2 inline-block align-text-top"/>
                                        Copy link
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col lg:flex-row gap-4 w-full py-6 border-t border-[#E4E7EC]">
                            <div className="w-80 shrink-0 text-sm font-medium text-[#101F2F]">
                                Your referrals
                            </div>
                            <div className="w-full text-sm text-darkgrey gap-y-3 flex flex-col">
                                {referrals && referrals.length > 0 ? (
                                    referrals.map((referral) => (
                                        <div key={referral.id} className="flex justify-between items-center">
                                            <span>{referral.user.email}</span>
                                            <span>
                                                {referral.state}
                                            </span>
                                            <div className={`px-2 py-1 rounded-full ${
                                                referral.state === 'subscribed' && referral.is_considered ? 'pl-3 pr-2 bg-green-100 text-green-800' : 'px-2 bg-gray-100 text-gray-800'
                                            }`}>
                                                <span>+{referral.rewarded_months} months</span>
                                                {referral.state === 'subscribed' && referral.is_considered && (
                                                    <CheckCircleIcon
                                                        className="ml-2 inline-block w-5 h-5"/>
                                                )}
                                            </div>
                                            <span>{formatDate(referral.created_at)}</span>
                                        </div>
                                    ))
                                ) : (
                                    <div>No referrals yet.</div>
                                )}
                            </div>
                        </div>

                    </div>
                </ContentBox>
            )}

        </>
    );
}

export default requireAuth(SettingsBillingPage);
