import { RiArrowDownSLine, RiLoader4Fill } from "@remixicon/react";
import React, { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import {updateDealState} from "../../util/api";
import {toast} from "react-toastify";

const StateTag = ({ dealId, initialState, type }) => {
    const STATES = [
        'created',
        'unfulfilled',
        'denied',
        'expired',
        'fulfilled',
        'completed',
    ];

    const STATE_TEXT_COLOR = {
        created: "#24962A",
        unfulfilled: "#ff7a00",
        denied: "#ff7a00",
        expired: "#ff7a00",
        fulfilled: "#24962A",
        completed: "#24962A",
    }

    const STATE_BG_COLOR = {
        created: "#EFFFEF",
        unfulfilled: "#FFF4E0",
        denied: "#FFF4E0",
        expired: "#FFF4E0",
        fulfilled: "#EFFFEF",
        completed: "#EFFFEF",
    }

    const [isLoading, setIsLoading] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [activeState, setActiveState] = useState(initialState);

    const handleModalClose = () => {
        setShowDropdown(false);
    };

    const handleModalOpen = () => {
        if (availableStates > 1 && activeState === 'unfulfilled' || activeState === 'fulfilled') {
            setShowDropdown(true);
        }
    };

    const handleStateChange = (newState) => {
        setIsLoading(true);
        try {
            updateDealState(dealId, newState).then((response) => {
                if (response.status === 'success') {
                    toast.success(`Deal state was updated to ${newState}!`);
                    setActiveState(newState);
                }
            });
        } catch (error) {
            toast.error('Error updating deal state:', error.message);
        } finally {
            handleModalClose();
            setIsLoading(false);
        }
    };

    const getAvailableStates = () => {
        if (activeState === 'unfulfilled' && type === 'WTS') {
            return ['unfulfilled', 'fulfilled'];
        } else if (activeState === 'fulfilled' && type === 'WTB') {
            return ['fulfilled', 'completed'];
        }
        return [activeState];
    };

    const availableStates = getAvailableStates();

    return (
        <div className="inline relative">
            <span
                className={`capitalize rounded-2xl font-medium text-xs py-1 pl-2.5 pr-2 text-[${STATE_TEXT_COLOR[activeState]}] bg-[${STATE_BG_COLOR[activeState]}] ${availableStates.length > 1 ? 'cursor-pointer' : ''}`}
                onClick={handleModalOpen}
            >
                <span className={`${availableStates.length > 1 ? 'pr-1.5 border-r border-darkgrey/30' : ''}`}>
                    {activeState}
                </span>
                {availableStates.length > 1 && (
                    <>
                        {isLoading && <RiLoader4Fill size={16} className="inline-block align-text-top ml-1 animate-spin" />}
                        {!isLoading && <RiArrowDownSLine size={18} className="inline ml-0.5"/>}
                    </>
                )}
            </span>
            {showDropdown && (
                <ClickAwayListener onClickAway={handleModalClose}>
                    <ul className="mt-1.5 border border-[#eee] absolute bg-white rounded-lg py-1 shadow-lg font-normal text-sm cursor-pointer z-10">
                        {availableStates.map((state, index) => (
                            <li
                                className={`${index !== 0 ? 'border-t' : ''} border-[#eee]`}
                                key={`${state}-${index}`}
                            >
                                <span
                                    className={`py-0.5 px-2.5 py-1.5 px-3 block ${state === activeState ? 'font-semibold' : ''}`}
                                    onClick={() => handleStateChange(state)}
                                >
                                    {state}
                                </span>
                            </li>
                        ))}
                    </ul>
                </ClickAwayListener>
            )}
        </div>
    );
};

export default StateTag;