import React from "react";
import Meta from "./../components/Meta";
import FaqSection from "./../components/FaqSection";
import CtaSection from "./../components/CtaSection";

function FaqPage(props) {
    return (
        <>
            <Meta title="Faq" />
            <FaqSection
                title="Frequently Asked Questions"
                subtitle=""
                strapline="We answer"
                size="md"
                bgColor="bg-white"
                bgImage=""
                bgImageOpacity={1}
                textColor=""
                showSupportButton={false}
                supportUrl="https://zendesk.com"
            />
            <CtaSection
                title={
                    <>
                        Revolutionize Your Sneaker Trading with Kavo!
                    </>
                }
                subtitle="Join the leading AI-powered Discord community tailored for effortless sneaker trading"
                strapline=""
                size="md"
                bgColor="bg-white"
                bgImage=""
                bgImageOpacity={1}
                textColor=""
            />
        </>
    );
}

export default FaqPage;
