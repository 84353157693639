import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";

function FaqSection(props) {
    const faqItems = [
        {
            question: "What is Kavo and how does it work?",
            answer: "Kavo is a sneaker trading tool that integrates with Discord, allowing users to manage their inventory and trade sneakers easily.",
        },
        {
            question: "How do I get started selling sneakers with Kavo?",
            answer: "You can sign up using your Discord account and list your sneakers for sale within the Kavo platform.",
        },
        {
            question: "What makes Kavo different from other sneaker trading platforms?",
            answer: "Kavo integrates with Discord, making it convenient for sneaker sellers and buyers to negotiate directly in real-time.",
        },
        {
            question: "How does Kavo’s AI-powered matchmaking system find buyers for my sneakers?",
            answer: "Kavo uses an AI algorithm to match sellers with buyers based on preferences and past behaviors.",
        },
        {
            question: "Can I track my trades directly within Discord?",
            answer: "Yes, all trades and interactions are managed through Discord, keeping everything centralized and easy to follow.",
        },
        {
            question: "What payment methods are supported on Kavo?",
            answer: "Payment methods depend on user agreements, as the platform facilitates connections between buyers and sellers.",
        },
        {
            question: "How secure is trading on Kavo?",
            answer: "Kavo prioritizes user safety with integrated security measures and the support of Discord’s secure infrastructure.",
        },
        {
            question: "Do I need a Discord account to use Kavo?",
            answer: "Yes, Kavo requires a Discord account for access and interaction with the platform.",
        },
        {
            question: "How do I negotiate deals through Kavo?",
            answer: "Buyers and sellers can communicate directly through Discord to negotiate prices and finalize deals.",
        },
        {
            question: "Is Kavo free to use, and are there any hidden fees?",
            answer: "Kavo is a free tool for its users, with no hidden fees.",
        },
        {
            question: "How do I buy sneakers on this platform?",
            answer: "To buy sneakers, navigate to the 'want-to-buy' channel and use the `/wtb` command. Post the desired products with details like names or SKUs, size, quantity, and optionally, price. For example, '/wtb Nike Dunk low Grey Fog Eu 42 3x'. You can list multiple products in one command.",
        },
        {
            question: "What's the process for selling sneakers?",
            answer: "To sell sneakers, go to the 'want-to-sell' channel and use the `/wts` command. List your products with details such as names or SKUs, size, and quantity. For instance, '/wts 2x DD1391-103 us 8.5 2x Yeezy 350 Beluga EU43'. You can list multiple products at once.",
        },
        {
            question: "How does the matching system work?",
            answer: "Kavo automatically finds potential matches between buyers and sellers. When a match is found, you'll receive a DM notification from the Kavo - Info Bot with all the match details. You can then review the match and decide if you want to engage in it.",
        },
        {
            question: "How do I confirm a deal?",
            answer: "Once you've agreed on deal conditions with your counterparty, go to the 'deals' channel and use the `/deal` command followed by the deal details. After you send the deal, your counterparty will receive a DM to accept or deny it.",
        },
        {
            question: "How is my personal data protected?",
            answer: "As a company based in Austria, we comply with DSGVO (GDPR) regulations. We store only necessary data, such as your address, username, and Discord account information. Payment-specific data is not stored by us, only Stripe customer and subscription IDs.",
        },
        {
            question: "Can I see my transaction history?",
            answer: "Yes, you can view your historical data through our web application. This provides an overview of all your past transactions and deals.",
        }
    ];

    return (
        <Section
            size={props.size}
            bgColor={props.bgColor}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
            textColor={props.textColor}
        >
            <div className="space-y-12 container">
                <SectionHeader
                    title={props.title}
                    subtitle={props.subtitle}
                    strapline={props.strapline}
                    className="text-center"
                />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
                    {faqItems.map((item, index) => (
                        <div className="prose prose-indigo" key={index}>
                            <h4>{item.question}</h4>
                            <p>{item.answer}</p>
                        </div>
                    ))}
                </div>

                {props.showSupportButton && (
                    <div className="text-center">
                        <Button
                            href={props.supportUrl}
                            target="_blank"
                            size="lg"
                            variant="simple"
                            startIcon={<ArrowTopRightOnSquareIcon className="opacity-50 inline-block w-5 h-5" />}
                        >
                            Go to support center
                        </Button>
                    </div>
                )}
            </div>
        </Section>
    );
}

export default FaqSection;
