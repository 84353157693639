import React, { useState, useEffect } from "react";
import Sidebar from "../components/navigation/Sidebar";
import Header from "../components/navigation/Header";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../util/api";
import {useAuth} from "../util/auth";
import InfoBar from "../components/common/InfoBar";

const AppLayout = ({ children }) => {
    const auth = useAuth();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [subscribedCount, setSubscribedCount] = useState(0);

    const handleSidebarOpen = () => {
        setIsSidebarOpen(true);
        document.body.style.overflow = "hidden";
    };
    const handleSidebarClose = () => {
        setIsSidebarOpen(false);
        document.body.style.overflow = "auto";
    };

    useEffect(() => {
        const fetchReferrals = async () => {
            if (auth.user) {
                try {
                    const response = await api.get('/profile/referrals');
                    const subscribed = response.data.data.filter(referral => referral.state === "subscribed").length;
                    setSubscribedCount(subscribed);
                } catch (error) {
                    console.error('Error fetching referrals:', error);
                }
            }
        };

        fetchReferrals();
    }, [auth.user]);

    return (
        <>
            {/* {(auth.user && !auth.user.planIsActive) && <InfoBar />} */}
            <Header
                handleSidebarOpen={handleSidebarOpen}
            />
            <div className="flex h-full">
                <Sidebar
                    isOpen={isSidebarOpen}
                    handleSidebarClose={handleSidebarClose}
                    subscribedCount={subscribedCount}
                />
                <div className="w-full pb-4 lg:pb-10 sbar:ml-64">
                    {children}
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
};

export default AppLayout;