import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import privacy from "../templates/privacy-policy.md";

function LegalPrivacy({ companyName, domain, contactEmail }) {
    const [markdownContent, setMarkdownContent] = useState("");

    useEffect(() => {
        fetch(privacy)
            .then((response) => response.text())
            .then((text) => {
                setMarkdownContent(text);
            })
            .catch((error) => {
                console.error("Error fetching markdown file:", error);
            });
    }, []);

    return (
        <div className="prose prose-a:text-blue-600 max-w-none">
            <ReactMarkdown>{markdownContent}</ReactMarkdown>
        </div>
    );
}

export default LegalPrivacy;
