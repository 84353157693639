import React, { useState, useEffect, useMemo, useContext, createContext } from "react";
import queryString from "query-string";
import supabase from "./supabase";
import {useUser, updateUser, updateDiscordUser} from "./db";
import { history } from "./router";
import PageLoader from "./../components/PageLoader";
import { getFriendlyPlanId } from "./prices";
import analytics from "./analytics";
import { DEFAULT_PAGE } from "./../data/nav";
import {setAuthToken} from "./api";

// Whether to merge extra user data from database into `auth.user`
const MERGE_DB_USER = true;

// Whether to connect analytics session to `user.uid`
const ANALYTICS_IDENTIFY = true;

// Create a `useAuth` hook and `AuthProvider` that enables
// any component to subscribe to auth and re-render when it changes.
const authContext = createContext();
export const useAuth = () => useContext(authContext);
// This should wrap the app in `src/pages/_app.js`
export function AuthProvider({ children }) {
    const auth = useAuthProvider();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook that creates the `auth` object and handles state
// This is called from `AuthProvider` above (extracted out for readability)
function useAuthProvider() {
    // Store auth user in state
    // `user` will be object, `null` (loading) or `false` (logged out)
    const [user, setUser] = useState(null);

    // store session in state
    const [session, setSession] = useState(null);


    // Merge extra user data from the database
    // This means extra user data (such as payment plan) is available as part
    // of `auth.user` and doesn't need to be fetched separately. Convenient!
    let finalUser = useMergeExtraData(user, { enabled: MERGE_DB_USER });

    // Add custom fields and formatting to the `user` object
    finalUser = useFormatUser(finalUser);

    // Connect analytics session to user
    useIdentifyUser(finalUser, { enabled: ANALYTICS_IDENTIFY });

    // Handle response from auth functions (`signup`, `signin`, and `signinWithProvider`)
    const handleAuth = async (response) => {
        const { data: { user, session } } = response;

        // If email is unconfirmed throw error to be displayed in UI
        // The user will be confirmed automatically if email confirmation is disabled in Supabase settings
        if (!user.email_confirmed_at) {
            throw new Error("Thanks for signing up! Please check your email to complete the process.");
        }

        // Update user in state
        setUser(user);
        setSession(session);
        setAuthToken(session.access_token);
        
        return user;
    };

    const signup = (email, password) => {
        let referralCode = queryString.parse(window.location.search).ref;
        const options = referralCode ? { data: { referral_code: referralCode } } : {};
        return supabase.auth.signUp({
            email,
            password,
            options
        }).then(handleError).then(handleAuth);
    };

    const signin = (email, password) => {
        return supabase.auth.signInWithPassword({ email, password }).then(handleError).then(handleAuth);
    };

    const signinWithProvider = (name) => {
        return (
            supabase.auth
                .signInWithOAuth({
                    provider: name,
                    options: {
                        redirectTo: `${window.location.origin}${DEFAULT_PAGE}`,
                    },
                })
                .then(handleError)
                // Because `signInWithOAuth` resolves immediately we need to add this so
                // it never resolves (component will display loading indicator indefinitely).
                // Once social signin is completed the page will redirect to value of `redirectTo`.
                .then(() => {
                    return new Promise(() => null);
                })
        );
    };

    const signinWithMagicLink = (email) => {
        return supabase.auth
            .signInWithOtp({
                email,
                options: {
                    emailRedirectTo: `${window.location.origin}${DEFAULT_PAGE}`,
                },
            })
            .then(handleError);
    };

    const signout = () => {
        return supabase.auth.signOut().then(handleError).then(() => {
            setUser(false);
            setSession(null);
            setAuthToken(null);
        });
    };

    const sendPasswordResetEmail = (email) => {
        return supabase.auth
            .resetPasswordForEmail(email, {
                redirectTo: `${window.location.origin}/auth/changepass`,
            })
            .then(handleError);
    };

    const confirmPasswordReset = (password) => {
        return supabase.auth.updateUser({ password }).then(handleError);
    };

    const updatePassword = (password) => {
        return supabase.auth.updateUser({ password }).then(handleError);
    };

    // Update auth user and persist data to database
    // Call this function instead of multiple auth/db update functions
    const updateProfile = async (data) => {
        const { email, ...other } = data;

        // If email changed let them know to click the confirmation links
        // Will be persisted to the database by our Supabase trigger once process is completed
        if (email && email !== user.email) {
            await supabase.auth.updateUser({ email }).then(handleError);
            throw new Error(
                "To complete this process click the confirmation links sent to your new and old email addresses",
            );
        }

        // Persist all other data to the database
        if (Object.keys(other).length > 0) {
            await updateUser(user.id, other);
        }
    };

    useEffect(() => {
        // Get hash portion of URL if coming from Supabase OAuth or magic link flow.
        // Store on `window` so we can access in other functions after hash is removed.
        window.lastHash = queryString.parse(window.location.hash);

        // If we have an `access_token` from OAuth or magic link flow avoid using
        // cached session so that user is `null` (loading state) until process completes.
        // Otherwise, a redirect to a protected page after social auth will redirect
        // right back to login due to cached session indicating they are logged out.
        if (! window.lastHash.access_token) {
            supabase.auth.getSession().then(({ data: { session } }) => {
                if (session) {
                    setUser(session.user);
                    setSession(session);
                    setAuthToken(session.access_token);
                } else {
                    setUser(false);
                    setSession(null);
                    setAuthToken(null);
                }
            });
        } else {
            // OAuth or magic link flow is in progress
            supabase.auth.getSession().then(({ data: { session } }) => {
                if (session) {
                    setUser(session.user);
                    // Handle Discord-specific logic here
                    if (session.user?.app_metadata?.provider === 'discord') {
                        const discordId = session?.user?.user_metadata?.provider_id;
                        if (discordId) {
                            console.log("Updating Discord user data");
                            updateDiscordUser({
                                id: session.user.id,
                                discord_id: discordId,
                                username: session.user.user_metadata.full_name,
                                email: session.user.email,
                                access_token: session.access_token,
                                refresh_token: session.refresh_token,
                                updated_at: new Date().toISOString(),
                            }).then(() => {
                                // Ensure the user state is updated after Discord data is saved
                                const referralCode = localStorage.getItem('referralCode');
                                const userData = {
                                    discord_user_id: discordId,
                                    ...(referralCode && !session.user.referred_by && { referred_by: referralCode })
                                };
                                updateUser(session.user.id, userData).then();

                                setUser(prevUser => ({ ...prevUser, discord_id: discordId }));
                            });
                        }
                    }
                } else {
                    setUser(false);
                }
            });
        }

        // Subscribe to user on mount
        const {
            data: {
                subscription: { unsubscribe },
            },
        } = supabase.auth.onAuthStateChange((event, session) => {
            if (session) {
                setUser(session.user);
                setSession(session);
                setAuthToken(session.access_token);
            } else {
                setUser(false);
                setSession(null);
                setAuthToken(null);
            }
        });

        // Unsubscribe on cleanup
        return () => unsubscribe();
    }, []);

    return {
        user: finalUser,
        session,
        signup,
        signin,
        signinWithProvider,
        signinWithMagicLink,
        signout,
        sendPasswordResetEmail,
        confirmPasswordReset,
        updatePassword,
        updateProfile,
    };
}

function useFormatUser(user) {
    // Memoize so returned object has a stable identity
    return useMemo(() => {
        // Return if auth user is `null` (loading) or `false` (not authenticated)
        if (!user) return user;

        // Create an array of user's auth providers by id (["password", "google", etc])
        // Components can read this to prompt user to re-auth with the correct provider
        let provider = user.app_metadata.provider;
        // Supabase calls it "email", but our components expect "password"
        if (provider === "email") provider = "password";
        const providers = [provider];

        // Get customer data
        const customer = user.customers || {};
        const discord_user = user.discord_users || {};

        return {
            // Include full auth user data
            ...user,
            // Alter the names of some fields
            uid: user.id,
            // User's auth providers
            providers: providers,
            // Add customer data
            ...customer,
            ...discord_user,
            // Add `planId` (starter, pro, etc) based on Stripe Price ID
            ...(customer.stripePriceId && {
                planId: getFriendlyPlanId(customer.stripePriceId),
            }),
            // Add `planIsActive: true` if subscription status is active or trialing
            planIsActive: ["active", "trialing"].includes(customer.stripeSubscriptionStatus),
        };
    }, [user]);
}

function useMergeExtraData(user, { enabled }) {
    // console.log("auth.js[useMergeExtraData]", "user", user);
    // Get extra user data from database
    const { data, status, error } = useUser(enabled && user && user.id);

    // console.log("auth.js[useMergeExtraData]", "data", data);

    // Memoize so returned object has a stable identity
    return useMemo(() => {
        // If disabled or no auth user (yet) then just return
        if (!enabled || !user) return user;

        switch (status) {
            case "success":
                // If successful, but `data` is `null`, that means user just signed up and the `createUser`
                // function hasn't populated the db yet. Return `null` to indicate auth is still loading.
                // The above call to `useUser` will re-render things once the data comes in.
                if (data === null) return null;
                // Return auth `user` merged with extra user `data`
                return { ...user, ...data };
            case "error":
                // Uh oh.. Let's at least show a helpful error.
                throw new Error(`
          Error: ${error.message}
          This happened while attempting to fetch extra user data from the database
          to include with the authenticated user. Make sure the database is setup or
          disable merging extra user data by setting MERGE_DB_USER to false.
        `);
            default:
                // We have an `idle` or `loading` status so return `null`
                // to indicate that auth is still loading.
                return null;
        }
    }, [user, enabled, data, status, error]);
}

// Connect analytics session to current user
function useIdentifyUser(user, { enabled }) {
    useEffect(() => {
        if (user && enabled) {
            analytics.identify(user.uid);
        }
    }, [user, enabled]);
}

// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
    return function RequireAuthHOC(props) {
        // Get authenticated user
        const auth = useAuth();
        const address = auth?.user?.addresses;

        useEffect(() => {
            // Redirect if not signed in
            if (auth.user === false) {
                history.replace("/auth/signin");
            } else {
                if (address && (!auth.user?.first_name || !auth.user?.last_name || !address?.city || !address?.postal_code ||
                    !address?.street_number || !address?.street || !address?.country)) {
                    history.replace("/settings/my-details");
                }
            }
        }, [auth]);

        // Show loading indicator
        // We're either loading (user is `null`) or about to redirect from above `useEffect` (user is `false`)
        if (!auth.user) {
            return <PageLoader />;
        }

        // Render component now that we have user
        return <Component {...props} />;
    };
};

// Throw error from auth response
// so it can be caught and displayed by UI
function handleError(response) {
    if (response.error) throw response.error;
    return response;
}
