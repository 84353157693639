import React from "react";
import ContentBox from "../components/common/ContentBox";
import ProductThumbnail from "../components/common/ProductThumbnail";
import PartnerInfoBox from "../components/common/PartnerInfoBox";

function DealItemsTable({ data, dealType }) {

    return (
        <div>
            <div className="lg:hidden">
                <PartnerInfoBox
                    partnerData={dealType === 'WTB' ? data.seller : data.buyer }
                    partnerType={dealType === 'WTB' ? 'Seller' : 'Buyer' }
                />

                {data && data.deal_items.map((item) => (
                    <ContentBox
                        key={item.id}
                        classes="mb-3.5 font-medium"
                    >
                        <div className="text-sm flex items-center mb-2.5">
                            {
                                item.products.image_url && <ProductThumbnail imageUrl={item.products.image_url} />
                            }
                            <div className={`${item.products.image_url ? '' : 'mt-2'}`}>
                                {item.products.name}
                            </div>
                        </div>
                        <table className="w-full table-auto mt-1">
                            <tbody>
                                <tr>
                                    <td className="py-1.5 w-32 text-xs uppercase text-darkgrey">Sku</td>
                                    <td className="py-1.5 text-sm">{item.products.sku}</td>
                                </tr>
                                <tr>
                                    <td className="py-1.5 w-32 text-xs text-darkgrey">Size</td>
                                    <td className="py-1.5 text-sm">{item.product_variants.size}</td>
                                </tr>
                                <tr>
                                    <td className="py-1.5 w-32 text-xs text-darkgrey">Quantity</td>
                                    <td className="py-1.5 text-sm">{item.quantity} {item.quantity === 1 ? 'item': 'items'}</td>
                                </tr>
                                {item.price && (
                                    <tr>
                                        <td className="py-1.5 w-32 text-xs text-darkgrey">Price</td>
                                        <td className="py-1.5 text-sm">{item.price}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </ContentBox>
                ))}
            </div>

            <div className="hidden lg:flex items-start">

                <ContentBox classes="lg:mr-0 w-full">
                    {data && <table className="w-full text-left text-sm font-medium whitespace-nowrap">
                        <thead className="text-darkgrey text-xs uppercase h-14">
                            <tr>
                                <th className="p-4 bg-grey rounded-l-lg ">Item name</th>
                                <th className="p-4 bg-grey">Sku</th>
                                <th className="p-4 bg-grey">Size</th>
                                <th className="p-4 bg-grey">Quantity</th>
                                <th className="p-4 bg-grey rounded-r-lg">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.deal_items.map((item) => (
                                <tr className="h-20" key={item.id}>
                                    <td className="p-4">
                                        <div className="flex items-center">
                                            {item.products.image_url && <ProductThumbnail imageUrl={item.products.image_url} />}
                                            <div className="whitespace-normal inline-block align-middle">
                                                {item.products.name}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="p-4">{item.products.sku}</td>
                                    <td className="p-4">{item.product_variants.size}</td>
                                    <td className="p-4">{item.quantity}</td>
                                    <td className="p-4">{item.price ?? '-'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}

                </ContentBox>

                <PartnerInfoBox
                    partnerData={dealType === 'WTB' ? data.seller : data.buyer }
                    partnerType={dealType === 'WTB' ? 'Seller' : 'Buyer' }
                />
            </div>
        </div>
    );
}

export default DealItemsTable;