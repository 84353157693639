import React, { useState } from "react";
import { RiDeleteBinLine, RiLoader4Fill } from "@remixicon/react";
import ContentBox from "../components/common/ContentBox";
import ProductThumbnail from "../components/common/ProductThumbnail";
import {deleteListing} from "../util/api";
import {toast} from "react-toastify";

function ListingsTable({ listings, listingType, onListingDeleted }) {
    const [isDeleting, setIsDeleting] = useState(false);

    const handleListingDelete = async (listing) => {
        if (listing.isDeleting || listing.isDeleted) return;

        listing.isDeleting = true;
        setIsDeleting(true);

        try {
            await deleteListing(listing.id);
            toast.success("Listing deleted!");
            listing.isDeleted = true;
            onListingDeleted(listing.id);
        } catch (error) {
            toast.error("Error deleting listing");
        } finally {
            setIsDeleting(false);
            listing.isDeleting = false;
        }
    }

    const NoDataMessage = () => (
        <tr>
            <td colSpan="7" className="text-center pt-8 pb-5 text-darkgrey">
                {listingType === 'BUY' ? "No listings data available" : "No listings data available"}
            </td>
        </tr>
    );

    return (
        <>
            <div className="lg:hidden">
                {listings.length > 0 ? (
                    listings.map((listing) => (
                        <ContentBox
                            key={listing.id}
                            classes={`mb-3.5 font-medium ${listing.isDeleted ? 'opacity-30 curosor-default' : ''}`}
                        >
                            <div className="text-sm flex items-center mb-2.5">
                                {listing.products.image_url &&
                                    <ProductThumbnail imageUrl={listing.products.image_url}/>}
                                <div className={`${listing.products.image_url ? '' : 'mt-2'}`}>
                                    {listing.products.name}
                                </div>
                            </div>
                            <table className="w-full table-auto my-1">
                                <tbody>
                                <tr>
                                    <td className="py-1.5 w-32 text-xs uppercase text-darkgrey">Sku</td>
                                    <td className="py-1.5 text-sm">{listing.products.sku}</td>
                                </tr>
                                <tr>
                                    <td className="py-1.5 w-32 text-xs uppercase text-darkgrey">Size</td>
                                    <td className="py-1.5 text-sm">{listing.product_variants.size}</td>
                                </tr>
                                <tr>
                                    <td className="py-1.5 w-32 text-xs text-darkgrey">Quantity</td>
                                    <td className="py-1.5 text-sm">{listing.quantity} {listing.quantity === 1 ? 'item' : 'items'}</td>
                                </tr>
                                {listing.price && (
                                    <tr>
                                        <td className="py-1.5 w-32 text-xs text-darkgrey">Price</td>
                                        <td className="py-1.5 text-sm">{listing.price}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            <button
                                className={`align-middle text-xs h-9 w-24 inline-flex items-center justify-center rounded-lg bg-grey`}
                                onClick={() => handleListingDelete(listing)}
                                type="button"
                                disabled={listing.isDeleting || listing.isDeleted}
                            >
                                <RiDeleteBinLine size="16" className="inline-block mr-1 text-red-600"/> Delete
                                {listing.isDeleting && (
                                    <RiLoader4Fill size={20} className="inline-block align-middle ml-2 animate-spin" />
                                )}
                            </button>
                        </ContentBox>
                    ))
                ) : (
                    <ContentBox classes="mb-3.5 font-medium">
                        <div className="text-center py-8 text-darkgrey">
                            {listingType === 'BUY' ? "You currently do not have listings you want to buy." : "You currently do not have listings you want to sell."}
                        </div>
                    </ContentBox>
                )}
            </div>

            <div className="hidden lg:flex items-start">
                <ContentBox classes="w-full">
                    <table className="w-full text-left text-sm font-medium whitespace-nowrap">
                        <thead className="text-darkgrey text-xs uppercase h-14">
                        <tr>
                            <th className="p-4 bg-grey rounded-l-lg">Product name</th>
                            <th className="p-4 bg-grey">SKU</th>
                            <th className="p-4 bg-grey">Size</th>
                            <th className="p-4 bg-grey">Quantity</th>
                            <th className="p-4 bg-grey">Price</th>
                            <th className="p-4 bg-grey rounded-r-lg"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {listings.length > 0 ? (
                            listings.map((listing) => (
                                <tr
                                    className={`h-20 ${listing.isDeleted ? 'opacity-30 curosor-default' : ''}`}
                                    key={listing.id}
                                >
                                    <td className="p-4">
                                        <div className="flex items-center">
                                            {listing.products.image_url &&
                                                <ProductThumbnail imageUrl={listing.products.image_url}/>}
                                            <div className="whitespace-normal inline-block align-middle">
                                                {listing.products.name}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="p-4">{listing.products.sku}</td>
                                    <td className="p-4">{listing.product_variants.size}</td>
                                    <td className="p-4">{parseInt(listing.quantity, 10)}</td>
                                    <td className="p-4">{listing.price ?? '-'}</td>
                                    <td className="p-4">
                                        <button
                                            className={`align-middle text-xs h-9 w-24 inline-flex items-center justify-center rounded-lg bg-grey`}
                                            onClick={() => handleListingDelete(listing)}
                                            type="button"
                                            disabled={listing.isDeleting || listing.isDeleted}
                                        >
                                            <RiDeleteBinLine size="16" className="inline-block mr-1 text-red-600"/> Delete
                                            {listing.isDeleting && (
                                                <RiLoader4Fill size={20} className="inline-block align-middle ml-2 animate-spin" />
                                            )}
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <NoDataMessage/>
                        )}
                        </tbody>
                    </table>
                </ContentBox>
            </div>
        </>
    );
}

export default ListingsTable;