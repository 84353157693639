import React from "react";
import { FireIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import { Link } from "./../util/router";

function CtaSection(props) {
    return (
        <Section
            size={props.size}
            bgColor={props.bgColor}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
            textColor={props.textColor}
        >
            <div className="space-y-10 container">
                <div className="text-center">
                    <div className="relative inline-flex w-25 h-25 items-center justify-center text-emerald-500 mb-10 mx-auto">
                        <div className="relative">
                            <img
                                className="block cursor-pointer"
                                src="/svg/kavo-logo.svg"
                                width={204}
                                height={48}
                                alt="Kavo Logo"
                            />
                        </div>
                    </div>
                    <SectionHeader title={props.title} subtitle={props.subtitle} strapline={props.strapline}/>
                    <div className="text-center">
                        <Button
                            component={Link}
                            to="/auth/signin"
                            size="xl"
                            endIcon={<ArrowRightIcon className="opacity-70 inline-block w-5 h-5" />}
                        >
                            Let's get started
                        </Button>
                    </div>
                </div>
            </div>
        </Section>
    );
}

export default CtaSection;
